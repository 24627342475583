import * as React from 'react';
import { useRouter } from 'next/navigation';
import { useSessionUser } from '@/hooks/useSessionUser';
import { Button } from '@/components/_catalyst/button';
import RoomCreateModal from '@/components/_modals/RoomCreateModal';
import UserCreateModal from '@/components/_modals/UserCreateModal';
import { QuestionRoom } from '@prisma/client';
import Link from 'next/link';

export default function Page() {
  const router = useRouter();
  const { user, isCompleted } = useSessionUser();
  const [displayCreateRoom, setDisplayCreateRoom] = React.useState(false);
  const [displayCreateUser, setDisplayCreateUser] = React.useState(false);

  const handlShowCreateRoom = React.useCallback((e: React.MouseEvent) => {
    setDisplayCreateRoom(true);
  }, []);
  const handleCloseCreateRoom = React.useCallback(() => {
    setDisplayCreateRoom(false);
  }, []);

  const handleCreateRoom = React.useCallback(
    (room: QuestionRoom) => {
      router.push(`/rooms/${room.id}`);
    },
    [router],
  );

  const handlShowCreateUser = React.useCallback((e: React.MouseEvent) => {
    setDisplayCreateUser(true);
  }, []);
  const handleCloseCreateUser = React.useCallback(() => {
    setDisplayCreateUser(false);
  }, []);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      {isCompleted && (
        <>
          {user ? (
            <div>
              <Button
                onClick={handlShowCreateRoom}
                className="w-40"
                color="white"
              >
                ルームを作成する
              </Button>
              {user?.rooms && (user?.rooms ?? []).length > 0 && (
                <div className="p-4 mt-8 mb-8 rounded-xl bg-white">
                  <p>以前のルーム</p>
                  <div className="p-4">
                    {user.rooms.map((room) => {
                      return (
                        <Link
                          key={room.id}
                          href={`/rooms/${room.id}`}
                          className="bg-gray-100 rounded-lg mr-2 p-1 pl-2 pr-2 "
                        >
                          {room.title}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Button
              onClick={handlShowCreateUser}
              className="w-50"
              color="white"
            >
              ユーザーを作成する
            </Button>
          )}

          {displayCreateRoom && (
            <RoomCreateModal
              onSubmit={handleCreateRoom}
              onClose={handleCloseCreateRoom}
            />
          )}
          {displayCreateUser && (
            <UserCreateModal onClose={handleCloseCreateUser} />
          )}
        </>
      )}
    </div>
  );
}
