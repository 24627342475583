import * as React from 'react';
import api from '@/libs/api';
import { useSessionUser } from './useSessionUser';
import { Answer, Game, QuestionRoom } from '@/types/rooms';
import { Question, QuestionPickedEvent } from '@/types/question';
import { ReviewType } from '@/schema/validation';
import { RoomMessage } from '@/types/roomMessage';
import { ParticipationUser, User } from '@/types/user';

export function useRoom(questionRoomId?: string) {
  const { user } = useSessionUser();

  const [room, setRoom] = React.useState<QuestionRoom>();
  const [questions, setQuestions] = React.useState<Question[]>([]);

  const roomRef = React.useRef<QuestionRoom>();
  roomRef.current = room;
  const [isFetching, setIsFetching] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [isCorrected, setIsCorrected] = React.useState(false);

  const getGyakunatorQuestion = async () => {
    const res = await api.get('/api/v1/gyakunator_questions');
    if ('error' in res) {
      return;
    }
    setQuestions(res);
  };

  const getRoom = async (questionRoomId: string) => {
    setIsFetching(true);
    setIsCompleted(false);
    const res = await api.get('/api/v1/rooms/:questionRoomId', {
      params: { questionRoomId },
    });
    setIsFetching(false);
    setIsCompleted(true);
    if ('error' in res) {
      return;
    }
    setRoom(res);
  };

  const createRoom = React.useCallback(
    async ({ title, password }: { title: string; password?: string }) => {
      if (!user) return;
      const res = await api.post('/api/v1/rooms', {
        title,
        password,
      });
      if ('error' in res) {
        return;
      }
      return res;
    },
    [user],
  );

  const selectQuestion = React.useCallback(
    async ({ roomId, questionId }: { roomId: string; questionId: string }) => {
      if (!user) return;
      setIsFetching(true);
      const res = await api.post(
        '/rooms/:questionRoomId/questions/:questionId/pick',
        {
          questionRoomId: roomId,
          questionId,
        },
      );
      setIsFetching(false);
      if (typeof res !== 'boolean' && 'error' in res) {
        return;
      }
      return res;
    },
    [user],
  );

  const selectGyakunatorQuestion = React.useCallback(
    async ({ roomId, questionId }: { roomId: string; questionId: string }) => {
      if (!user) return;
      setIsFetching(true);
      setIsCorrected(false);
      const res = await api.post(
        '/api/v1/rooms/:questionRoomId/gyakunator/:questionId/start',
        {
          questionRoomId: roomId,
          questionId,
        },
      );
      setIsFetching(false);
      if ('error' in res) {
        return;
      }
      return res;
    },
    [user],
  );
  const joinRoom = React.useCallback(
    async ({
      questionRoomId,
      password,
      socketId,
    }: {
      questionRoomId: string;
      password: string;
      socketId: string;
    }) => {
      if (!user) return;
      const res = await api.post('/api/v1/rooms/:questionRoomId/join', {
        questionRoomId,
        password,
        socketId,
      });
      if (typeof res !== 'boolean' && 'error' in res) {
        return;
      }
      return res;
    },
    [user],
  );

  const askQuestion = async (params: {
    questionRoomId: string;
    message: string;
  }) => {
    setIsCompleted(false);
    try {
      const res = await api.post('/api/v1/rooms/:questionRoomId/ask', params);
      if ('error' in res) {
        // TODO エラー表示処理
        setIsCompleted(true);
        return;
      }
      setIsCompleted(true);
    } catch (error) {
      setIsCompleted(true);
    }
  };

  const answerQuestion = async (params: {
    questionRoomId: string;
    message: string;
  }) => {
    try {
      setIsCompleted(false);
      const res = await api.post(
        '/api/v1/rooms/:questionRoomId/answer',
        params,
      );
      if ('error' in res) {
        // TODO エラー表示処理
        setIsCompleted(true);
        return;
      }
      setIsCompleted(true);
    } catch (error) {
      setIsCompleted(true);
    }
  };

  const reviewQuestion = async (params: {
    questionId: string;
    reviewType: ReviewType;
  }) => {
    setIsCompleted(false);
    const res = await api.post('/api/v1/questions/:questionId/review', params);
    setIsCompleted(true);
    if (typeof res !== 'boolean' && 'error' in res) {
      return;
    }
  };

  const refetch = React.useCallback(async () => {
    if (!questionRoomId) return;
    getRoom(questionRoomId);
  }, [questionRoomId]);

  const finishQuestion = async (params: { questionRoomId: string }) => {
    setIsCompleted(false);
    const res = await api.post('/api/v1/rooms/:questionRoomId/finish', params);
    setIsCompleted(true);
    setIsCorrected(true);
    if ('error' in res) {
      return;
    }
  };

  const updatePlayingQuestion = React.useCallback(
    (updatedData: QuestionPickedEvent) => {
      if (!roomRef.current || !roomRef.current.members) return;
      setIsCorrected(false);
      setRoom({
        ...roomRef.current,
        ...updatedData,
        messages: [],
        members: roomRef.current.members.map((_user) => {
          return {
            ..._user,
            ranking: undefined,
          };
        }),
      });
    },
    [room],
  );

  const updateParticipationUser = React.useCallback(
    (member: ParticipationUser) => {
      if (!roomRef.current || !roomRef.current.members) return;
      const members = roomRef.current.members.map((_user) => {
        if (_user.id === member.id) {
          return { ...member, additionalPoints: member.point - _user.point };
        }
        return { ..._user, additionalPoints: null };
      });
      setRoom({
        ...roomRef.current,
        members: members.sort((a, b) => b.point - a.point),
      });
    },
    [room, user],
  );

  const joinPariticpationUsers = React.useCallback(
    (user: ParticipationUser) => {
      if (!roomRef.current || !roomRef.current.members) return;
      setRoom({
        ...roomRef.current,
        members: [...roomRef.current.members, user],
      });
    },
    [room],
  );

  const updatePariticpationUsers = React.useCallback(
    (member: ParticipationUser) => {
      if (!roomRef.current || !roomRef.current.members) return;
      setRoom({
        ...roomRef.current,
        members: roomRef.current.members.map((_user) => {
          if (_user.id === member.id) {
            return { ...member };
          }
          return { ..._user };
        }),
      });
    },
    [room],
  );

  const updateRoomMessages = React.useCallback(
    (roomMessage: RoomMessage) => {
      if (!roomRef.current || !roomRef.current.messages) return;
      setRoom({
        ...roomRef.current,
        messages: [roomMessage, ...roomRef.current.messages],
      });
    },
    [room],
  );

  const updateHint = React.useCallback(
    (hint: string) => {
      if (!roomRef.current) return;
      setRoom({
        ...roomRef.current,
      });
    },
    [room],
  );

  const startGame = React.useCallback(
    (game: Game | null, room: QuestionRoom, playingAnswer: Answer | null) => {
      if (!roomRef.current) return;
      setRoom({
        ...roomRef.current,
        ...room,
        game,
        messages: [],
        members: roomRef.current.members.map((_user) => {
          return {
            ..._user,
            ranking: null,
          };
        }),
        playingAnswer,
      });
    },
    [room],
  );

  const completeGame = React.useCallback(
    (game: Game | null, room: QuestionRoom, playingAnswer: Answer | null) => {
      if (!roomRef.current) return;
      setRoom({
        ...roomRef.current,
        ...room,
        game,
        playingAnswer,
      });
    },
    [room],
  );

  React.useEffect(() => {
    if (!questionRoomId || !user) return;
    getRoom(questionRoomId);
  }, [user]);

  return {
    room,
    questions,
    isFetching,
    isCompleted,
    isCorrected,
    refetch,
    createRoom,
    completeGame,
    updatePlayingQuestion,
    joinPariticpationUsers,
    updateParticipationUser,
    updateHint,
    updateRoomMessages,
    joinRoom,
    selectQuestion,
    startGame,
    selectGyakunatorQuestion,
    getGyakunatorQuestion,
    askQuestion,
    answerQuestion,
    reviewQuestion,
    finishQuestion,
    updatePariticpationUsers,
  };
}
