'use client';

import * as React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForwardRefHandlers, ModalLayout } from '../ModalLayout';
import { Button } from '@/components/_catalyst/button';
import { useSessionUser } from '@/hooks/useSessionUser';
import { createUserSchema } from '@/schema/validation';

interface Props {
  onClose?: () => void;
  onSubmit?: () => void;
}

type FromData = { name: string };

export default function UserCreateModal({ onClose, onSubmit }: Props) {
  const ref = React.useRef<ForwardRefHandlers>(null);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(createUserSchema),
  });
  const { user, signup } = useSessionUser();

  const handleClose = React.useCallback(() => {
    ref.current?.close();
  }, []);

  const handleCreateUser = React.useCallback(
    async ({ name }: FromData) => {
      if (user) return;
      await signup(name);
      if (typeof onSubmit === 'function') {
        onSubmit();
      }
      ref.current?.close();
    },
    [user, signup, onSubmit],
  );

  return (
    <ModalLayout ref={ref} onClose={onClose}>
      <form onSubmit={handleSubmit(handleCreateUser)}>
        <div className="p-5">
          <div className="text-xl font-bold mb-4">ユーザーを作成</div>
          <div className="text-base"></div>
        </div>
        <div className="p-5 pt-0">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            プレイヤー名
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="プレイヤー名"
            {...register('name')}
          />
        </div>
        <div className="flex relative flex-wrap-reverse sm:flex-nowrap gap-4 px-5 py-4 border-t border-gray-200 justify-center align-center">
          <Button className="w-full sm:w-1/2" onClick={handleClose} outline>
            キャンセル
          </Button>
          <Button type={'submit'} className="w-full sm:w-1/2">
            作成する
          </Button>
        </div>
      </form>
    </ModalLayout>
  );
}
