'use client';

import * as React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForwardRefHandlers, ModalLayout } from '../ModalLayout';
import { Button } from '@/components/_catalyst/button';
import { useRoom } from '@/hooks/useRoom';
import { useSessionUser } from '@/hooks/useSessionUser';
import { createRoomSchema } from '@/schema/validation';
import { QuestionRoom } from '@prisma/client';

interface Props {
  onClose?: () => void;
  onSubmit?: (room: QuestionRoom) => void;
}

type FromData = { title: string; password?: string };

export default function RoomCreateModal({ onSubmit, onClose }: Props) {
  const ref = React.useRef<ForwardRefHandlers>(null);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      title: '',
      password: '',
    },
    resolver: yupResolver(createRoomSchema),
  });
  const { user } = useSessionUser();
  const { createRoom } = useRoom();

  const handleClose = React.useCallback(() => {
    ref.current?.close();
  }, []);

  const handleCreateRoom = React.useCallback(
    async (params: FromData) => {
      if (!user) return;
      const res = await createRoom({ ...params });
      ref.current?.close();
      if (typeof onSubmit === 'function' && res) {
        onSubmit(res);
      }
    },
    [user, createRoom, onSubmit],
  );

  return (
    <ModalLayout ref={ref} onClose={onClose}>
      <form onSubmit={handleSubmit(handleCreateRoom)}>
        <div className="p-5">
          <div className="text-xl font-bold mb-4">ルーム作成</div>
          <div className="text-base"></div>
        </div>
        <div className="p-5 pt-0">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            タイトル
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="タイトル"
            {...register('title')}
          />
        </div>
        <div className="p-5 pt-0">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            パスワード（任意）
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="パスワード"
            {...register('password')}
          />
        </div>
        <div className="flex relative flex-wrap-reverse sm:flex-nowrap gap-4 px-5 py-4 border-t border-gray-200 justify-center align-center">
          <Button
            className="w-full sm:w-1/2 dark:text-gray-600"
            onClick={handleClose}
            outline
          >
            キャンセル
          </Button>
          <Button type={'submit'} className="w-full sm:w-1/2">
            作成する
          </Button>
        </div>
      </form>
    </ModalLayout>
  );
}
